import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Suspense, lazy, createContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { INACTIVE_TIMEOUT_DETAILS } from '../../constants/constants';
import InActiveSpeedBump from '../../components/InActiveSpeedBump/InActiveSpeedBump';
import UserLoggedInDetails from "../../utils/UserLoggedInDetails";
import styles from '../../App.module.scss';
const ScrollToHash = lazy(() => import('../../components/ScrollToHash/ScrollToHash'));
const Header = lazy(() => import('../../components/Header/Header'));
const Footer = lazy(() => import('../../components/Footer/Footer'));
const SpeedBump = lazy(() => import('../../components/SpeedBump/SpeedBump'));
const Home = lazy(() => import('../Home/Home'));
const Accounts = lazy(() => import('../Accounts/Accounts'));
const PurchaseOrEarlyTurnIn = lazy(() => import('../Lease/PurchaseOrEarlyTurnIn/PurchaseOrEarlyTurnIn'));
const Explore = lazy(() => import('../Lease/Explore/Explore'));
const LeaseReturn = lazy(() => import('../Lease/Return/Return'));
const Finance = lazy(() => import('../Finance/Finance'));
const LoanEnd = lazy(() => import('../Loan/Loan'));
const ErrorPage = lazy(() => import('../ErrorPage/ErrorPage'));
const Lease = lazy(() => import('../Lease/Lease'));
const Faq = lazy(() => import('../Faq/Faq'));
const TurnIn = lazy(() => import('../Lease/TurnIn/TurnIn'));
const TurnInConfirmation = lazy(() => import('../Lease/TurnInConfirmation/TurnInConfirmation'));
const Sitemap = lazy(() => import('../Sitemap/Sitemap'));
const NewCustomer = lazy(() => import('../NewCustomerPage/NewCustomer'));
const ContactUs = lazy(() => import('../ContactUs/ContactUs'));
const PreApprovalOffer = lazy(() => import('../PreApprovalOfferPage/PreApprovalOffer'));
const { timeout, warningTimeout } = INACTIVE_TIMEOUT_DETAILS;
export const ModalContext = createContext({});
const AppView = () => {
    const [modalObj, setModalObj] = useState({
        modalFlag: false,
        type: '',
        exLink: '',
    });
    const openModal = (type, url) => {
        setModalObj((prevData) => ({
            ...prevData,
            modalFlag: true,
            type: type,
            exLink: url,
        }));
    };
    const closeModal = () => {
        setModalObj((prevData) => ({
            ...prevData,
            modalFlag: false,
            type: '',
            exLink: '',
        }));
    };
    return (_jsx(UserLoggedInDetails, { children: _jsx(ModalContext.Provider, { value: { openModal, closeModal }, children: _jsxs(Suspense, { fallback: _jsx("div", { className: `${styles.suspenseLoader} spinner-border position-absolute top-50 start-50`, role: "status" }), children: [_jsx(ScrollToHash, {}), _jsx(InActiveSpeedBump, { timeout: timeout, warningTimeout: warningTimeout }), _jsx(Header, {}), _jsx("div", { className: styles.appContainer, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/myaccount", element: _jsx(Accounts, {}) }), _jsx(Route, { path: "/sitemap", element: _jsx(Sitemap, {}) }), _jsx(Route, { path: "/lease-end/return", element: _jsx(LeaseReturn, {}) }), _jsx(Route, { path: "/lease-end/other", element: _jsx(PurchaseOrEarlyTurnIn, {}) }), _jsx(Route, { path: "/financing", element: _jsx(Finance, {}) }), _jsx(Route, { path: "/loan-end", element: _jsx(LoanEnd, {}) }), _jsx(Route, { path: "/error", element: _jsx(ErrorPage, {}) }), _jsx(Route, { path: "/pay-manage", element: _jsx(ErrorPage, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/oops" }) }), _jsx(Route, { path: "/oops", element: _jsx(ErrorPage, {}) }), _jsx(Route, { path: "/lease-end", element: _jsx(Lease, {}) }), _jsx(Route, { path: "/lease-end/new", element: _jsx(Explore, {}) }), _jsx(Route, { path: "/faq", element: _jsx(Faq, {}) }), _jsx(Route, { path: "/welcome", element: _jsx(NewCustomer, {}) }), _jsx(Route, { path: "/contact-us", element: _jsx(ContactUs, {}) }), _jsx(Route, { path: "/lease-end-turnin", element: _jsx(TurnIn, {}) }), _jsx(Route, { path: "/lease-end-turnin-confirmation", element: _jsx(TurnInConfirmation, {}) }), _jsx(Route, { path: "/myoffers", element: _jsx(PreApprovalOffer, {}) })] }) }), _jsx(Footer, {}), _jsx(SpeedBump, { showModal: modalObj.modalFlag, hideModal: closeModal, type: modalObj.type, externalLink: modalObj.exLink })] }) }) }));
};
export default AppView;
